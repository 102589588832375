$(document).foundation();

jQuery(document).ready(function($) {

  // Input character counter
  $('#microchip-number').on("input", function () {

    var currentLength = $(this).val().length;

    $('#ctr-display').text(currentLength);
    $('#ctr-display').attr('data-ctr', currentLength);

  });
});
